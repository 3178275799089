import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
  Link,
  Image,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver'; // To download the generated PDF
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
  },
  section: {
    marginBottom: 10,
    padding: 10,
    border: '1px solid #ccc',
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  productRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
    marginRight: 10,
  },
  image: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
  },
  link: {
    marginTop: 10,
    fontSize: 12,
    color: 'blue',
    textDecoration: 'underline',
  },
  priceTable: {
    marginTop: 10,
    border: '1px solid #ccc',
    borderCollapse: 'collapse',
    width: '100%',
  },
  priceTableHeader: {
    borderBottom: '1px solid #ccc',
    fontWeight: 'bold',
    padding: 5,
  },
  priceTableRow: {
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceTableCell: {
    padding: 5,
    flex: 1,
    textAlign: 'center',
  },
});

// Create Document Component
const MyDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Pcdeals Hardware Product's</Text>
      {!!data &&
        data.map((product, index) => (
          <View key={index} style={styles.section}>
            <View style={styles.productRow}>
              <View style={styles.column}>
                <Text>Name: {product.name}</Text>
                <Text>Sale: {product.sale}/-</Text>
                <Text>MRP: {product?.mrp}/-</Text>
                <Text>Min Order Qty: {product?.minqty}</Text>
                <Text>Rewards: {product.reward}</Text>
                
                {/* Conditional rendering for Prices */}
                {product?.price && Object.keys(product.price).length > 0 && (
                  <View style={styles.priceTable}>
                    <View style={styles.priceTableRow}>
                      <Text style={styles.priceTableCell}>Qty</Text>
                      <Text style={styles.priceTableCell}>Price</Text>
                    </View>
                    {Object.entries(product.price).map(([key, value], index) => (
                      <View key={index} style={styles.priceTableRow}>
                        <Text style={styles.priceTableCell}>{key}</Text>
                        <Text style={styles.priceTableCell}>{value}/-</Text>
                      </View>
                    ))}
                  </View>
                )}

                <Link
                  src={`https://pcdealshardware.com/product/${product?.slug}?productType=${product?.productType}`}
                  style={styles.link}
                >
                  Buy Now
                </Link>
              </View>

              <View style={styles.column}>
                {product?.images?.[product?.defaultImage] && (
                  <Image
                    src={`${product?.images?.[product?.defaultImage]}`}
                    style={styles.image}
                  />
                )}
              </View>
            </View>
          </View>
        ))}
    </Page>
  </Document>
);

const ExportPdf = ({ products }) => {
  const { productsListExportData } = useSelector((state) => state.commonReducer);

  const handleDownload = async () => {
    // Create the PDF blob
    const blob = await pdf(<MyDocument data={productsListExportData} />).toBlob();

    // Use FileSaver to download the file
    saveAs(blob, 'Pcdeals Hardware Products.pdf');
  };

  return (
    <div>
      <Button variant="warning" className="f-right" onClick={handleDownload}>
        Export PDF
      </Button>
    </div>
  );
};

export default ExportPdf;
