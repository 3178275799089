import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getCustomizeProducts, groupList } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  status: yup.boolean().required(),
  category: yup.string().required(),
});

function GroupAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const {
    title,
    popupTitle,
    api,
    name,
    description,
    status,
    category,
    id,
    // subProduct,
  } = props;

  const handleShow = () => {
    setShow(true);
  };

  const { getCustomizeProductsList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Button className="bg-thememain" onClick={handleShow}>
        {title === "Group" ? "Add" : <i className="bi bi-pen-fill"></i>} {title}
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{popupTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if (apiResp?.payload.status) {
              resetForm({ values: "" });
              handleClose();
              dispatch(groupList());
            }
          }}
          initialValues={{
            name,
            description,
            status,
            category,
            id,
            // subProduct: subProduct ? subProduct.map((item) => String(item.id)) : [],
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Label>Select Category</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setFieldValue("category", e.target.value);
                      dispatch(getCustomizeProducts({ category: e.target.value }));
                    }}
                    name="category"
                    className="form-control"
                    value={values.category}
                    isInvalid={!!errors.category}
                  >
                    <option value="">Select Category</option>
                    <option value="Processor">Processor</option>
                    <option value="Ram">Ram</option>
                    <option value="Storage">Storage</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.category}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setFieldValue("status", /true/.test(e.target.value));
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      rows={3}
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Select Sub-Products</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        "subProduct",
                        selectedOptions?.map((data) => data.value)
                      );
                    }}
                    defaultValue={
                      getCustomizeProductsList?.list
                        ?.filter((data) => values.subProduct.includes(String(data.id)))
                        .map((data) => ({
                          value: String(data.id),
                          label: data.name,
                        }))
                    }
                    options={
                      getCustomizeProductsList?.list?.map((data) => ({
                        value: String(data.id),
                        label: data.name,
                      })) || []
                    }
                  />
                  <p className="text-danger">{errors.subProduct}</p>
                </Col>
              </Row> */}
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default GroupAction;
