import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addCategorie, getCategories } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  parent: yup.string(),
  name: yup.string().required(),
  slug: yup.string().required(),
  description: yup.string(),
  image: yup.mixed().required(),
  status: yup.string().required(),
  orderby: yup.string(),
});

function CategoriesAdd(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { getCategoriesData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add Categories
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Categories</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            dispatch(addCategorie(values));
            resetForm({ values: "" });
            dispatch(getCategories());
            handleClose();
          }}
          initialValues={{
            parent: "",
            name: "",
            slug: "",
            description: "",
            status: "",
            image: "",
            orderby: "",
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Label htmlFor="parent">Parent Categorie</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("parent", e.target.value);
                    }}
                    name="parent"
                    className="form-control"
                    value={values.parent}
                    isInvalid={!!errors.parent}
                  >
                    <option value="">Select ParentCat</option>
                    {!!getCategoriesData?.list &&
                      getCategoriesData?.list.map((data, index) => {
                        return (
                          <option key={index} value={data?.id}>
                            {data?.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="slug">Slug</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Slug"
                      name="slug"
                      value={
                        (values.slug = values.name
                          .toLowerCase()
                          .replace(/ /g, "-")
                          .replace(/[^\w-]+/g, ""))
                      }
                      onChange={handleChange}
                      isInvalid={!!errors.slug}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.slug}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="description">Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Label htmlFor="status">Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="myfile">Image</Form.Label>
                    <Form.Control
                      id="myfile"
                      type="file"
                      name="image"
                      onChange={(event) => {
                        const resumeFile = event.target.files[0];
                        setFieldValue("image", resumeFile);
                      }}
                      isInvalid={!!errors.image}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.image}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group controlId="validationFormik01">
                    <Form.Label>OrderBY</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="orderby"
                      name="orderby"
                      value={values.orderby}
                      onChange={handleChange}
                      isInvalid={!!errors.orderby}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.orderby}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default CategoriesAdd;
