import React from "react";

const Banner = (props) => {
  return (
    <>
        <img src={props?.bannerImg}/>
    </>
  );
};

export default Banner;
