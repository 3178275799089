import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { about, home, contact, shop, servicecareCenter } from "../../const";
import { getPages, getSinglePage } from "../../reducers/frontEndReducer";
import moment from "moment";

const Footer = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  useEffect(() => {
    dispatch(getPages());
  }, []);
  const { getPagesList } = useSelector((state) => state.frontEndReducer);
  const { getSocialMediaSettingsData } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <footer>
        {/* fixed nav start */}
        <nav className="mobile-bottom-nav hide-lg-window">
          <div className="mobile-bottom-nav__item">
            <div className="mobile-bottom-nav__item-content">
              <Link to={home}>
                <i className="bi bi-house-add-fill"></i>
              </Link>
            </div>
          </div>
          <div className="mobile-bottom-nav__item">
            <div className="mobile-bottom-nav__item-content">
              <Link to={shop}>
                <i className="bi bi-shop"></i>
              </Link>
            </div>
          </div>
          <div className="mobile-bottom-nav__item">
            <div className="mobile-bottom-nav__item-content">
              <a href={`tel:+91${getSocialMediaSettingsData?.phone}`}>
                <i className="bi bi-telephone-fill"></i>
              </a>
            </div>
          </div>

          <div className="mobile-bottom-nav__item">
            <div className="mobile-bottom-nav__item-content">
              <a
                href={`https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi. I am intrested in Your Services..&source=&data=&app_absent=`}
              >
                <i className="bi bi-whatsapp"></i>
              </a>
            </div>
          </div>
        </nav>
        {/* fixed nav end */}
        <div className="footer__area">
          <div className="footer__top black-bg pt-80 pb-40">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="footer__widget">
                        <div className="footer__widget-title footer__widget-title-2">
                          <h4>Company Info</h4>
                        </div>
                        <div className="footer__widget-content">
                          <div className="footer__link footer__link-2">
                            <ul>
                              <li>
                                <NavLink to={home}>Home</NavLink>
                              </li>
                              <li>
                                <NavLink to={shop}>Shop</NavLink>
                              </li>
                              <li>
                                <NavLink to={about}>About us</NavLink>
                              </li>
                              <li>
                                <NavLink to={contact}>Contact us</NavLink>
                              </li>
                              <li>
                                <NavLink to={servicecareCenter}>
                                  Service Center
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div className="footer__widget">
                        <div className="footer__widget-title footer__widget-title-2">
                          <h4>INFORMATION</h4>
                        </div>
                        <div className="footer__widget-content">
                          <div className="footer__link footer__link-2">
                            <ul>
                              {!!getPagesList &&
                                getPagesList.map((data, index) => {
                                  return (
                                    <li key={index}>
                                      <Link
                                        onClick={() => {
                                          dispatch(
                                            getSinglePage({ slug: data?.slug })
                                          );
                                        }}
                                        to={`/${data?.slug}`}
                                      >
                                        {data?.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="footer__widget">
                    <div className="footer__widget-title footer__widget-title-2">
                      <h4>Social Media</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__social mb-20">
                        <ul>
                        <li className="whatsapp">
                            <a
                              href={`https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi. I am intrested in Your Services..&source=&data=&app_absent=`}
                              target="_blank"
                            >
                              <i className="bi bi-whatsapp"></i>
                            </a>
                          </li>
                          <li className="fb">
                            <a
                              href={getSocialMediaSettingsData?.facbook}
                              target="_blank"
                            >
                              <i className="bi bi-facebook"></i>
                            </a>
                          </li>
                          <li className="tw">
                            <a
                              href={getSocialMediaSettingsData?.twitter}
                              target="_blank"
                            >
                              <i className="bi bi-twitter"></i>
                            </a>
                          </li>
                          <li className="yt">
                            <a
                              href={getSocialMediaSettingsData?.youtube}
                              target="_blank"
                            >
                              <i className="bi bi-youtube"></i>
                            </a>
                          </li>
                          <li className="pt">
                            <a
                              href={getSocialMediaSettingsData?.mail}
                              target="_blank"
                            >
                              <i className="bi bi-envelope-fill"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="footer__download">
                        <h4>Download The App - Link</h4>
                        {/* <a to = "#" className="m-img">
                          <img
                            src="http://127.0.0.1:5500/assets/img/icon/app-store-2.jpg"
                            alt=""
                          />
                        </NavLink> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            width.current > 500 &&  <p className="fixed-whatsapp">
            <a href={`https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi. I am intrested in Your Services..&source=&data=&app_absent=`}
                              target="_blank">
                              <i className="bi bi-whatsapp"></i>
                            </a>
            </p>
          }
          <div>
          </div>
          <div className="footer__copyright black-bg ">
            <div className="container">
              <div className="footer__copyright-inner pt-2 pb-2">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="footer__copyright-text">
                      <p className="text-center text-white">
                      © {moment().year()} Pcdeals Hardware. All Rights Reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
