import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { Col, Row } from "react-bootstrap";
import {
  addGroup,
  groupList,
  updateGroup,
} from "../../reducers/commonReducer";
import { home } from "../../const";
import GroupAction from "./GroupAction";

const Groups = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(groupList());
    // dispatch(getCustomizeProducts());
  }, []);

  const { groupData } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row className="mt-1 mb-2">
            <Col md={4}>
              <GroupAction
              title="Group"
              popupTitle={"Add Group"}
              api={addGroup}
            />
            </Col>
        </Row>
        <table className="table w-100">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">Sr</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Category</th>
              <th className="over-col-size">Description</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!groupData &&
              groupData?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.category}</td>
                      <td>{data?.description}</td>
                      <td>
                        <b>{data?.status === true ? "True" : "False"}</b>
                      </td>
                      {/* <td>
                          {data?.subProduct?.map((product, pIndex) => {
                            return(
                              <span key={pIndex}><b>{pIndex + 1}.</b> {product?.name}<br/><hr/></span>
                            )
                          })}
                      </td> */}
                      <td>
                        <GroupAction
                          api={updateGroup}
                          popupTitle={"Update Group"}
                          name={data?.name}
                          category={data?.category}
                          description={data?.description}
                          status={data?.status}
                          id={data?.id}
                          // subProduct={data?.subProduct}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Groups;
