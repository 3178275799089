import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import CommonHeader from "./CommonHeader";
import {
  getProducts,
  getProductRating,
} from "../../reducers/commonReducer";
import {
  getCategoriesFrontEnd,
  getChildCategoriesFrontEnd,
} from "../../reducers/frontEndReducer";
import { Col, Row } from "react-bootstrap";
import { getBrandsFrontend } from "../../reducers/frontEndReducer";
import dummy from "../../images/dummy.jpeg";
import commingsoon from '../../images/commingson.jpg'
import {
  userAddWishList,
  userProductLikedList,
} from "../../reducers/orderDetailsReducer";
import { dynamicPriceRange, productRating, shop } from "../../const";
import darkheart from "../../images/darkheart.png";
import lightheart from "../../images/lightheart.png";

const Shop = () => {
  const timeoutIdRef = React.useRef();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const productId = queryParameters.get("ref_");
  const brandId = queryParameters.get("brdanRef_");
  const dispatch = useDispatch();
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  // const [processerPrice, setProcesserPrice] = useState();
  let width = window.innerWidth;
  useEffect(() => {
    dispatch(getCategoriesFrontEnd());
    dispatch(getProductRating());
    dispatch(getBrandsFrontend());
    localStorage.getItem("x-auth-token") && dispatch(userProductLikedList());
    dispatch(
      getProducts({
        categories: productId ? [productId] : "",
        brand: brandId ? brandId : "",
      })
    );
  }, []);

  const { productRatingData, getProductsData } = useSelector(
    (state) => state.commonReducer
  );
  const { userProductLikedData } = useSelector(
    (state) => state.orderDetailReducer
  );
  const {
    getCatsFrontEndData,
    childCategoriesFrontEndData,
    getBrandsFrontendData,
  } = useSelector((state) => state.frontEndReducer);
  const handleCheckboxChange = (subCat, event) => {
    let selectCat =
      subCat === false ? [...categories, event.target.id] : [...categories];
    let selectSubCat =
      subCat === true
        ? [...subcategories, event.target.id]
        : [...subcategories];
    if (categories.includes(event.target.id)) {
      selectCat = selectCat.filter((cat) => cat !== event.target.id);
    }
    if (subcategories.includes(event.target.id)) {
      selectSubCat = selectSubCat.filter((cat) => cat !== event.target.id);
    }
    dispatch(
      getProducts({
        categories: selectCat,
        subcategories: selectSubCat,
        brands,
        minprice: +minPrice,
        maxprice: +maxPrice,
      })
    );
    subCat === false &&
      dispatch(
        getChildCategoriesFrontEnd({
          parent: selectCat,
        })
      );
    setCategories(selectCat);
    setSubcategories(selectSubCat);
  };
  const handleCheckboxChangeBrands = (event) => {
    let selectBrand = [...brands, event.target.value];
    if (brands.includes(event.target.value)) {
      selectBrand = selectBrand.filter((brand) => brand !== event.target.value);
    }
    dispatch(
      getProducts({
        categories,
        subcategories,
        brands: selectBrand,
        minprice: +minPrice,
        maxprice: +maxPrice,
      })
    );
    setBrands(selectBrand);
  };
  let processerPrice;
  let storagePrice;
  let ramPrice
  return (
    <>
      <CommonHeader />
      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-md-2">
            <div className="sidebar">
              <div className="row">
                <div className="col-md-12 col-7">
                  <div className="sidebar-categories area">

                    <Accordion defaultActiveKey={width > 468 ? "1" : "0"}>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <span
                            href="#sidebar-categories"
                            className="sidebar-title"
                            data-bs-toggle="collapse"
                          >
                            Product Categories
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {!!getCatsFrontEndData &&
                              getCatsFrontEndData.map((cat, index) => {
                                return (
                                  <li className="subcat" key={index}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        name={cat?.name}
                                        id={cat?.id}
                                        value={cat?.id ? cat?.id : productId}
                                        onChange={(e) =>
                                          handleCheckboxChange(false, e)
                                        }
                                      />
                                      {cat?.name}
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                {!!childCategoriesFrontEndData.length > 0 && (
                  <div className="col-md-12 col-5">
                    <div className="sidebar-categories area">
                      <Accordion defaultActiveKey={width > 468 ? "1" : "0"}>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <span
                              href="#sidebar-categories"
                              className="sidebar-title"
                              data-bs-toggle="collapse"
                            >
                              Product SubCategories
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              {!!childCategoriesFrontEndData &&
                                childCategoriesFrontEndData.map(
                                  (cat, index) => {
                                    return (
                                      <li className="subcat" key={index}>
                                        <label>
                                          <input
                                            type="checkbox"
                                            name={cat?.name}
                                            id={cat?.id}
                                            value={cat?.id}
                                            onChange={(e) =>
                                              handleCheckboxChange(true, e)
                                            }
                                          />
                                          {cat?.name}
                                        </label>
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                )}
                <div className="col-md-12 col-5">
                  <div className="sidebar-sort area">
                    <Accordion defaultActiveKey={width > 468 ? "1" : "0"}>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <span
                            href="#sidebar-categories"
                            className="sidebar-title"
                            data-bs-toggle="collapse"
                          >
                            Brands
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {!!getBrandsFrontendData?.list &&
                              getBrandsFrontendData?.list.map(
                                (brands, index) => {
                                  return (
                                    <li className="subcat" key={index}>
                                      <label>
                                        <input
                                          type="checkbox"
                                          name={brands?.name}
                                          value={brands?.id}
                                          onChange={handleCheckboxChangeBrands}
                                        />
                                        {brands?.name}
                                      </label>
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="sidebar-filter area">
                    <Row className="hide-price-filter-text">
                      <Col md={9} xs={6}>
                        Filter by Price
                      </Col>
                      <Col md={3} xs={6}>
                        <span
                          onClick={() => {
                            setMinPrice(0);
                            setMaxPrice(100000);
                            dispatch(
                              getProducts({
                                categories:
                                  categories.length > 0 ? categories : "",
                                brands: brands.length > 0 ? brands : "",
                                minprice: 0,
                                maxprice: 100000,
                              })
                            );
                          }}
                          className="btn clear-btn btn-block"
                        >
                          Clear
                        </span>
                      </Col>
                    </Row>
                    <section className="mt-2">
                      <div className="d-flex-block">
                        <Row className="rang-dsign">
                          <Col md={12} xs={6}>
                            <Form.Label>
                              Min Price <span>({minPrice})</span>
                            </Form.Label>
                            <Form.Range
                              max="100000"
                              step="1"
                              value={minPrice}
                              onChange={(e) => {
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    brands: brands.length > 0 ? brands : "",
                                    minprice: +e.target.value,
                                    maxprice: +maxPrice,
                                  })
                                );
                                setMinPrice(e.target.value);
                              }}
                            />
                          </Col>
                          <Col md={12} xs={6}>
                            <Form.Label>
                              Max Price <span>({maxPrice})</span>
                            </Form.Label>
                            <Form.Range
                              max="100000"
                              step="1"
                              value={maxPrice}
                              onChange={(e) => {
                                setMaxPrice(e.target.value);
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    brands: brands.length > 0 ? brands : "",
                                    minprice: +minPrice,
                                    maxprice: +e.target.value,
                                  })
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-1">
                          <Col md={5} xs={5}>
                            <select
                              name="minPrice"
                              value={minPrice}
                              onChange={(e) => {
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    brands: brands.length > 0 ? brands : "",
                                    minprice: +e.target.value,
                                    maxprice: maxPrice,
                                  })
                                );
                                setMinPrice(e.target.value);
                              }}
                            >
                              <option value="Min">Min</option>
                              <option value="250">₹250</option>
                              <option value="500">₹500</option>
                              <option value="1000">₹1000</option>
                              <option value="2000">₹2000</option>
                              <option value="5000">₹5000</option>
                              <option value="10000">₹10000</option>
                              <option value="20000">₹20000</option>
                              <option value="30000">₹30000</option>
                              <option value="40000">₹40000</option>
                              <option value="50000">₹50000</option>
                              <option value="60000">₹60000</option>
                            </select>
                          </Col>
                          <Col md={2} xs={2}>
                            To
                          </Col>
                          <Col md={5} xs={5}>
                            <select
                              value={maxPrice}
                              onChange={(e) => {
                                dispatch(
                                  getProducts({
                                    categories:
                                      categories.length > 0 ? categories : "",
                                    brands: brands.length > 0 ? brands : "",
                                    minprice: +e.target.value,
                                    maxprice: +maxPrice,
                                  })
                                );
                                setMaxPrice(e.target.value);
                              }}
                            >
                              <option value="">Max</option>
                              <option value="250">₹250</option>
                              <option value="500">₹500</option>
                              <option value="1000">₹1000</option>
                              <option value="2000">₹2000</option>
                              <option value="5000">₹5000</option>
                              <option value="10000">₹10000</option>
                              <option value="20000">₹20000</option>
                              <option value="30000">₹30000</option>
                              <option value="40000">₹40000</option>
                              <option value="50000">₹50000</option>
                              <option value="60000">₹60000</option>
                              <option value="100000">₹100000+</option>
                            </select>
                          </Col>
                        </Row>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="row">
              {!!getProductsData?.list && getProductsData?.list.length <= 0 ? (
                <div className={width > 468 ? "" : "notfountmbl"}>
                  <img src={commingsoon} />
                </div>
              ) : (
                !!getProductsData?.list &&
                getProductsData.list.map((data, index) => {
                  const processer = data?.productOptions?.processer?.find(option => option.stock === true);
                  const ram = data?.productOptions?.ram?.find(option => option.stock === true);
                  const storage = data?.productOptions?.storage?.find(option => option.stock === true);

                  if (processer) processerPrice = processer.price;
                  if (ram) ramPrice = ram.price;
                  if (storage) storagePrice = storage.price;

                  return (
                    <div className="col-md-3 col-sm-6 col-6" key={index}>
                      <div className="product-grid mx-0 card">
                        <div className="product-image">

                          <Link to={`/product/${data?.slug}?productType=${data?.productType}`}>
                            <div className="card-img-actions ">
                              <div className="stock-status-container">
                                {data?.quantity === 0 ? (
                                  <b className={`stock-status out-of-stock`}>Out Of Stock</b>
                                ) : (
                                  <b className={`stock-status in-stock`}>In Stock</b>
                                )}
                              </div>
                              <img
                                src={
                                  data?.images
                                    ? `/${data?.images[data?.defaultImage]}`
                                    : dummy
                                }
                                className="custom-card-img img-fluid mbl-img-size"
                                alt=""
                              />
                            </div>
                          </Link>
                          {!!data?.sale && (
                            <span className="product-discount-label">
                              -
                              {(
                                (100 * (data?.mrp - data?.sale)) /
                                data?.mrp
                              ).toFixed(2)}
                              %
                            </span>
                          )}
                          <ul className="product-links">
                            <li>
                              <NavLink to={`/product/${data?.slug}?productType=${data?.productType}`}>
                                <i className="bi bi-search"></i>
                              </NavLink>
                            </li>
                            <li
                              onClick={async () => {
                                localStorage.getItem("x-auth-token") &&
                                  (await dispatch(
                                    userProductLikedData[data?.id]
                                      ? userAddWishList({
                                        productId: data?.id,
                                        status: false,
                                      })
                                      : userAddWishList({
                                        productId: data?.id,
                                        status: true,
                                      })
                                  ));
                                localStorage.getItem("x-auth-token") &&
                                  (await dispatch(
                                    getProducts({
                                      categories: productId ? [productId] : "",
                                      brand: brandId ? brandId : "",
                                    })
                                  ));
                                localStorage.getItem("x-auth-token") &&
                                  (await dispatch(userProductLikedList()));
                              }}
                            >
                              {!!userProductLikedData && (
                                <NavLink to="" className={"heartImg"}>
                                  {userProductLikedData[data?.id] === true ? (
                                    <img src={darkheart} />
                                  ) : (
                                    <img src={lightheart} />
                                  )}
                                </NavLink>
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="product-content position-relative">
                          <button
                            onClick={() => navigate(`/product/${data?.slug}?productType=${data?.productType}`)}
                            className="add-to-cart"
                          >
                            Add to Cart
                          </button>

                          <Link to={`/product/${data?.slug}?productType=${data?.productType}`}>
                            <div className="card-body text-center">
                              <div className="product-name-box">
                                <p
                                  className="product-fullname mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html: data?.name.substring(0, 45) + "...",
                                  }}
                                ></p>
                              </div>
                              <b className="product-name1" >
                                {data?.warranty}
                              </b>
                              <p className="mb-0">
                                <span className="fw-bold">
                                  Price Inclusive of GST
                                </span>
                              </p>
                              <hr />
                              <h3 className="mb-0 font-weight-semibold product__price">
                                {!!data?.price && Object.keys(data?.price)?.length > 0 && `Rs. ${dynamicPriceRange(data?.price)} -`}{data?.productType === "customize"
                                  ? `Rs. ${data?.sale +
                                  processerPrice +
                                  ramPrice +
                                  storagePrice
                                  }`
                                  : `Rs. ${data?.sale}`}
                              </h3>
                              <div className="rating">
                                {!!productRatingData &&
                                  productRatingData[data.id] &&
                                  productRating(productRatingData[data.id])}
                              </div>
                            </div>
                          </Link>
                          <p className="reward-offer mb-2 w-100">
                            {data?.rewardStatus && <>  <b><span>
                              <i className="bi bi-gift-fill"></i> Earn {data?.reward} Reward Points </span></b></>
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Shop;
