import React, { useState } from "react";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  adminOrders,
  resetAdminOrderList,
  updateOrderStatus,
} from "../../reducers/orderDetailsReducer";

function OrderUpdate(props) {
  const dispatch = useDispatch();
  const { orderStatus, title, orderId } = props;
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(orderStatus);
  const [trackingid, setTrackingid] = useState();
  const [trackingNumber, setTrackingNumber] = useState();
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateUser = async () => {
    setLoading(true);
    await dispatch(updateOrderStatus({ id: orderId, status: +status, trackingid, trackingNumber }));
    setLoading(false);
    await dispatch(resetAdminOrderList());
    await dispatch(adminOrders(
      {
      status:orderStatus
    }
  ));
    setShow(false);
  };
  const { getTrackersData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button className="m-1" variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Form.Group className="p-3">
          <Form.Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option>Order Status</option>
            <option value={0}>Pending</option>
            <option value={1}>Cancel</option>
            <option value={2}>Processing</option>
            <option value={3}>Shipping</option>
            <option value={4}>Completed</option>
            <option value={5}>Partially Delivered</option>
          </Form.Select>
        </Form.Group>
        
        {status == 3 && <>
        <Row className="px-3 mb-3">
          <Col md={6}>
          <Form.Group>
          <Form.Label>Tracking Company</Form.Label>
          <Form.Select
          value={trackingid}
          onChange={(e) => setTrackingid(e.target.value)}
        >
          <option>Select Tracker</option>
          {
            !!getTrackersData?.list && getTrackersData.list?.map((data, index) =>  
            <option key={index} value={data?.id}>{data?.name}</option>
            )
          }
        </Form.Select>
        </Form.Group>
          </Col>
          <Col md={6}>
          <Form.Group>
                    <Form.Label>Tracking Id</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Id"
                      name="trackingNumber"
                      value={trackingNumber}
                      onChange={(e)=>setTrackingNumber(e.target.value)}
                    />
                  </Form.Group>
          </Col>
        </Row>
        </>
        }
        <Modal.Footer>
        {loading ? <Spinner variant="success" animation="grow" /> : <Button variant="primary" onClick={updateUser}>
            Save
          </Button>}
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderUpdate;
