import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { downloadExcel } from "react-export-table-to-excel";
import { useDispatch } from "react-redux";

const ExportData = (props) => {
  const dispatch = useDispatch();
  const { title, header, body, tableName, api, filters } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [exportBody, setExportBody] = useState(body); 
  
  const handleDownloadExcel = async () => {
    if (api) {
      try {
        setIsLoading(true); 

        const response = await dispatch(api(filters));
        const updatedBody = response?.list || body;
        setExportBody(updatedBody);

      } catch (error) {
        console.error("Error while fetching data from API:", error);
      } finally {
        setIsLoading(false); 
      }
    } else {
      downloadExcel({
        fileName: tableName ? tableName : "Shyamg Data Record",
        sheet: tableName ? tableName : "Shree Shyam Snacks Food Pvt. Ltd.",
        tablePayload: {
          header: header,
          body: exportBody, 
        },
      });
    }
  };

  useEffect(() => {
    if (isLoading && body && body.length > 0) {
      downloadExcel({
        fileName: tableName ? tableName : "Pcdeals Hardware Record",
        sheet: tableName ? tableName : "Pcdeals Hardware",
        tablePayload: {
          header: header,
          body: body, 
        },
      });
    }
  }, [exportBody, header, tableName]);

  return (
    <div>
      <Button
        onClick={handleDownloadExcel}
        className="f-right btn btn-success"
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner animation="border" size="sm" /> 
        ) : (
          title
        )}
      </Button>
    </div>
  );
};

export default ExportData;

